import { useEffect } from 'react';
import { toast } from 'react-toastify';
import useFetch from 'react-fetch-hook';

import { Layout, Spinner } from '../components';

import { Hero, About, Services, Ticker, Reviews, Map, FAQ, Form } from '../sections';

export const ADMIN_URL = 'https://admin.wefix.group/wp-json/wp/v2';

const HomePage = () => {
  const { isLoading, data, error } = useFetch(`${ADMIN_URL}/pages?slug=home-page`);

  useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <Layout contacts={data[0]?.acf.contacts}>
      <Hero data={data[0]?.acf.hero_section} />
      <About data={data[0]?.acf.about_section} />
      <Services data={data[0]?.acf.services_section} />
      <Ticker />
      <Reviews data={data[0]?.acf.reviews_section} />
      <Map />
      <FAQ data={data[0]?.acf.faq} />
      <Form />
    </Layout>
  );
};

export default HomePage;
