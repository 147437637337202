import './about.scss';

const About = ({ data }) => (
  <section className="about" id="about">
    <div className="container">
      <div className="about__inner">
        <div className="about__grid">
          {data.grid.map((el, index) =>
            el.is_photo ? (
              <div className="about__grid--item" style={{ backgroundImage: `url(${el.photo})` }} key={index} />
            ) : (
              <div className="about__grid--item" key={index}>
                <div className="icon">
                  <img src={el.icon} alt="Fully Insured" />
                </div>
                <div className="title">{el.title}</div>
                <p>{el.text}</p>
              </div>
            ),
          )}
        </div>

        <div className="about__result">
          <p className="caption">
            <span>{data.result.caption.green_text}</span>
            <br />
            {data.result.caption.description}
          </p>

          <div className="counter">
            <div className="counter--item">
              <p>120+</p>
              <p>
                Qualified
                <br />
                specialists
              </p>
            </div>

            <div className="counter--item">
              <p>999+</p>
              <p>
                Successfully
                <br />
                implemented projects
              </p>
            </div>

            <div className="counter--item">
              <p>100%</p>
              <p>
                Commitment
                <br />
                to work
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default About;
