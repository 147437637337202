import Slider from 'react-slick';

import './reviews.scss';

const sliderSettings = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Reviews = ({ data }) => (
  <section className="reviews">
    <div className="container">
      <h2 className="section-title">What Our Clients Say</h2>

      <Slider className="reviews__list" {...sliderSettings}>
        {data.map((el, index) => (
          <div className="reviews__list--item" key={index}>
            <header>
              <div className="icon">
                <i className="fa-brands fa-google" />
              </div>

              <div className="info">
                <span>{el.name}</span>
                <span>{el.date}</span>
              </div>
            </header>
            <div className="stars">
              <img src="../assets/star.svg" alt="star" />
              <img src="../assets/star.svg" alt="star" />
              <img src="../assets/star.svg" alt="star" />
              <img src="../assets/star.svg" alt="star" />
              <img src="../assets/star.svg" alt="star" />
            </div>
            <p>{el.text}</p>
          </div>
        ))}
      </Slider>
    </div>
  </section>
);

export default Reviews;
