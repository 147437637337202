import { useState } from 'react';

import './faq.scss';

const Accordion = ({ title, children }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="accordion-wrapper">
      <div className={`accordion-title ${isOpen ? 'open' : ''}`} onClick={() => setOpen(!isOpen)}>
        {title}
      </div>
      <div className={`accordion-item ${!isOpen ? 'collapsed' : ''}`}>
        <div className="accordion-content">{children}</div>
      </div>
    </div>
  );
};

const FAQ = ({ data }) => {
  return (
    <section className="faq">
      <div className="container">
        <h2 className="section-title">Frequently Asked Questions</h2>

        <div className="faq__list">
          {data.map((el, index) => (
            <Accordion title={el.question} key={index}>
              {el.answer}
            </Accordion>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
