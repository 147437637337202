import './hero.scss';

const Hero = ({ data }) => (
  <section className="hero">
    <div className="container">
      <div className="hero__inner">
        <div className="caption">
          <p>
            <span>{data.green_text}</span> {data.description}
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default Hero;
