import axios from 'axios';
import { toast } from 'react-toastify';

import './form.scss';

const MAIL_URL = `https://admin.wefix.group/wp-json/contact-form-7/v1/contact-forms/8/feedback`;

const Form = () => {
  const submit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      const response = await axios.post(MAIL_URL, data);

      if (response.status === 200) {
        toast.success(response.data.message);
        event.target.reset();
      }
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please, try again!');
      console.log(error);
    }
  };

  return (
    <section className="form-wrapper">
      <div className="container">
        <div className="form-wrapper__inner">
          <div className="title">
            <h2>Do you have any questions?</h2>

            <p>Reach out to us, and we'll gladly address each one.</p>
          </div>

          <div className="form">
            <form onSubmit={submit}>
              <input type="text" placeholder="First Name *" name="first_name" required />
              <input type="text" placeholder="Last Name *" name="last_name" required />
              <input type="email" placeholder="Email *" name="user_email" required />
              <input type="tel" placeholder="Phone *" name="phone" required />
              <textarea placeholder="Message *" name="message" required />

              <input type="submit" value="Send" className="yellow-btn" />

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  lineHeight: '100%',
                  marginBottom: '30px',
                  color: '#070707',
                }}
              >
                <div>
                  <svg
                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="LockIcon"
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 5,
                      fill: '#070707',
                    }}
                  >
                    <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path>
                  </svg>
                </div>
                <p>Data protected and privacy</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Form;
