import Slider from 'react-slick';

import './ticker.scss';

const sliderSettings = {
  dots: false,
  arrows: false,
  infinite: true,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  // cssEase: 'cubic-bezier(0.600, -0.280, 0.735, 0.045)',
  // cssEase: 'cubic-bezier(0.6, 0.6, 0.6, 0.6)',
  variableWidth: true,
};

const Ticker = () => (
  <section className="ticker">
    <div className="container">
      <h2 className="section-title">
        <span>#1 Home Services</span>
        <br />
        Let's make your home
        <br />
        the best it can be
      </h2>

      <div className="ticker__line">
        <Slider className="ticker__slider" {...sliderSettings}>
          <div className="ticker__slider--slide">
            <img src="../assets/ticker_icons/arrow.svg" alt="Arrow" />
          </div>

          <div className="ticker__slider--slide">
            <span>Appliance Repair</span>
          </div>

          <div className="ticker__slider--slide">
            <img src="../assets/ticker_icons/cooler.svg" alt="Cooler" />
          </div>

          <div className="ticker__slider--slide">
            <span>HVAC</span>
          </div>

          <div className="ticker__slider--slide">
            <span>Plumbing</span>
          </div>

          <div className="ticker__slider--slide">
            <img src="../assets/ticker_icons/electric.svg" alt="Electric" />
          </div>

          <div className="ticker__slider--slide">
            <span>Electrical</span>
          </div>

          <div className="ticker__slider--slide">
            <span>Solar Panels</span>
          </div>

          <div className="ticker__slider--slide">
            <img src="../assets/ticker_icons/solar.svg" alt="Solar" />
          </div>

          <div className="ticker__slider--slide">
            <span>HVAC</span>
          </div>
        </Slider>

        <Slider className="ticker__slider" {...sliderSettings} rtl="true">
          <div className="ticker__slider--slide">
            <img src="../assets/ticker_icons/arrow.svg" alt="Arrow" />
          </div>

          <div className="ticker__slider--slide">
            <span>Appliance Repair</span>
          </div>

          <div className="ticker__slider--slide">
            <img src="../assets/ticker_icons/cooler.svg" alt="Cooler" />
          </div>

          <div className="ticker__slider--slide">
            <span>HVAC</span>
          </div>

          <div className="ticker__slider--slide">
            <span>Plumbing</span>
          </div>

          <div className="ticker__slider--slide">
            <img src="../assets/ticker_icons/electric.svg" alt="Electric" />
          </div>

          <div className="ticker__slider--slide">
            <span>Electrical</span>
          </div>

          <div className="ticker__slider--slide">
            <span>Solar Panels</span>
          </div>

          <div className="ticker__slider--slide">
            <img src="../assets/ticker_icons/solar.svg" alt="Solar" />
          </div>

          <div className="ticker__slider--slide">
            <span>HVAC</span>
          </div>
        </Slider>

        <Slider className="ticker__slider" {...sliderSettings}>
          <div className="ticker__slider--slide">
            <img src="../assets/ticker_icons/arrow.svg" alt="Arrow" />
          </div>

          <div className="ticker__slider--slide">
            <span>Appliance Repair</span>
          </div>

          <div className="ticker__slider--slide">
            <img src="../assets/ticker_icons/cooler.svg" alt="Cooler" />
          </div>

          <div className="ticker__slider--slide">
            <span>HVAC</span>
          </div>

          <div className="ticker__slider--slide">
            <span>Plumbing</span>
          </div>

          <div className="ticker__slider--slide">
            <img src="../assets/ticker_icons/electric.svg" alt="Electric" />
          </div>

          <div className="ticker__slider--slide">
            <span>Electrical</span>
          </div>

          <div className="ticker__slider--slide">
            <span>Solar Panels</span>
          </div>

          <div className="ticker__slider--slide">
            <img src="../assets/ticker_icons/solar.svg" alt="Solar" />
          </div>

          <div className="ticker__slider--slide">
            <span>HVAC</span>
          </div>
        </Slider>
      </div>
    </div>
  </section>
);

export default Ticker;
