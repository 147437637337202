import { useState, useEffect } from 'react';
import { Link } from 'react-scroll';

import './header.scss';

const Header = () => {
  const [shadow, setShadow] = useState(false);

  const handleScroll = () => {
    setShadow(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`page-header ${shadow && 'shadow'}`}>
      <div className="container">
        <div className="page-header__inner">
          <Link
            activeClass="active"
            to="hero"
            spy={true}
            smooth={true}
            offset={-200}
            duration={500}
            className="page-header__logo"
          >
            <img src="../assets/header_logo.svg" alt="We Fix Appliance Repair" />
            <div className="subtitle">Bringing All Home Services to Your Doorstep</div>
          </Link>

          <nav className="page-header__navigation">
            <ul>
              <li>
                <Link activeClass="active" to="about" spy={true} smooth={true} offset={-200} duration={500}>
                  About
                </Link>
              </li>
              <li>
                <Link activeClass="active" to="services" spy={true} smooth={true} offset={-200} duration={500}>
                  Services
                </Link>
              </li>
              <li>
                <Link activeClass="active" to="reviews" spy={true} smooth={true} offset={-200} duration={500}>
                  Testimonials
                </Link>
              </li>
              <li>
                <Link activeClass="active" to="form-wrapper" spy={true} smooth={true} offset={-180} duration={500}>
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
