import './footer.scss';

const Footer = ({ contacts }) => (
  <footer className="page-footer">
    <div className="container">
      <h2>Contact Us</h2>

      <div className="links">
        <a href={`mailto:${contacts.email}`} className="email">
          {contacts.email}
        </a>
        <a href={contacts.facebook} target="_blank" className="fb" rel="noReferrer">
          <i className="fab fa-facebook"></i>
        </a>
        <a href={contacts.yelp} target="_blank" className="yelp" rel="noReferrer">
          <i className="fab fa-yelp"></i>
        </a>
        <a href={contacts.instagram} target="_blank" className="instagram" rel="noReferrer">
          <i className="fab fa-instagram"></i>
        </a>
      </div>

      <a href={`tel:${contacts.phone}`} className="phone">
        {contacts.phone}
      </a>

      <div className="flex-group">
        <p className="address" dangerouslySetInnerHTML={{ __html: contacts.address }} />

        <img src="./assets/header_logo.svg" alt="WeFix Appliance Repair" className="logo" />
      </div>
    </div>
  </footer>
);

export default Footer;
