import './services.scss';

const Services = ({ data }) => (
  <section className="services" id="services">
    <div className="container">
      <div className="services__inner">
        <div>
          <h2 className="section-title">Our Services</h2>
        </div>

        {data.map((el, index) => (
          <div key={index}>
            <header>
              <div className="icon">
                <img src={el.icon} alt={el.title} />
              </div>
              <p>{el.title}</p>
            </header>
            <p>{el.description}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Services;
