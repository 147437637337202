import { Routes, Route } from 'react-router-dom';

import HomePage from './pages/HomePage';

export const ADMIN_URL = 'https://admin.wefix-appliance.co/wp-json/wp/v2';

const App = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
  </Routes>
);

export default App;
