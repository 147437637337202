import { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-scroll';

import { Header, Footer } from '../';

import './layout.scss';

const Layout = ({ contacts, children }) => {
  const [isVisible, setVisibility] = useState(false);

  const handleScroll = () => {
    setVisibility(window.scrollY > 250);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div>
      <Header />
      <main>{children}</main>
      <Footer contacts={contacts} />
      <ToastContainer />
      <div className={`mobile-cta-buttons ${isVisible ? 'on' : 'off'}`}>
        <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
        <Link activeClass="active" to="form-wrapper" spy={true} smooth={true} offset={-180} duration={500}>
          Contact Us
        </Link>
      </div>
    </div>
  );
};

export default Layout;
